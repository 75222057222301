import { v5 as uuidv5 } from 'uuid'
import { UserDevice } from '~/lib/services/im/devices.dto'

const DEVICE_CUSTOM_NAMESPACE = '93a04773-2dcf-4cce-a5a8-d2f9328ce497'

export const generateDeviceUuid = (userAgent: string) => {
  const str = userAgent.replace(/\s/, ':')
  return uuidv5(str, DEVICE_CUSTOM_NAMESPACE)
}

export const getDeviceId = (agent?: string): string => {
  if (isCypressSpecialBehaviour()) return 'my-awesome-device-identifier'
  const userAgent = agent || navigator.userAgent
  return generateDeviceUuid(userAgent)
}

const isAndroidOrIOS = (str: string | null) => {
  if (!str) return false
  return str.includes('iOS') || str.includes('Android')
}

export const getDeviceName = (device: UserDevice) => {
  if (isAndroidOrIOS(device.os) && !emptyStr(device.model)) return `${device.brand} ${device.model}`

  if (isAndroidOrIOS(device.os) && emptyStr(device.browser)) return device.os

  if (!isAndroidOrIOS(device.os)) return device.os

  return ''
}

export const getDeviceAppName = (device: UserDevice) => {
  if (isAndroidOrIOS(device.os) && emptyStr(device.browser)) return 'App'

  if (isAndroidOrIOS(device.os) && !emptyStr(device.browser)) return device.browser

  if (!isAndroidOrIOS(device.os)) return device.browser

  return ''
}
